.container34{
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  margin: 0px;
  position: absolute;
}

.cabecera1{
  display: flex;
  color: rgb(0, 0, 0);
  flex-direction: row;
  height:40px;
  width: 100%;
  background-color: #44a1a0;
  position: fixed;
  justify-content: right;  
  margin-right: 20px;
}
  
.contenido34{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: 80px;

}





.navi{
  width: 100%;
  height: 20%;
}


.cabecera{
  display: flex;
  color: rgb(0, 0, 0);
  flex-direction: row;

  height:40px;

  width: 100%;
  background-color: #44a1a0;
  position: fixed;
  
}

.vuelve{
  display: flex;
  width:20%;
  height: 100%;

}
.vuelve3{
  display: flex;
  width:40%;
  height: 100%;
  justify-items: right;
  justify-content: right;

}
.vuelve2{
  justify-content: right;
  display: flex;
  margin-left: 20%;
  width: 20%;
  height: 100%;
}
.a{
  display: flex;
  margin-bottom: 5px;
  width: 20%;
  height: 100%;
 font-weight: 500;
  font-size: 20px;
  margin-left: 20%;
  margin-top: 5px;
}
.a44{
  display: flex;
  margin-bottom: 5px;
  width: 30%;
  height: 100%;
 font-weight: 500;
  font-size: 20px;
  margin-left: 20%;
  margin-top: 5px;
}
.cuerpo{
  margin-top:40px;
  display: flex;
  flex-direction: row;
  height: 95%;
  width: 100%;
  font-weight: bold;
  color: black;
 

 
}
.select-numeroespecimen{
  background-color: transparent;
  border: none;
  height: 27px;
  font-weight: bold;
}
.pie{
  margin-top: 1px;
  display: flex;
align-items: center;
justify-content: center;
height:5%;
width: 100%;
background-color: #44a1a0;
cursor: pointer;
font-weight: bold;
color: white;
font-size: 19px;
transition: 0.5s;
}

.pieErr{
  display: flex;
align-items: center;
justify-content: center;
height:5%;
width: 100%;
background-color:  #6b705c;
cursor: pointer;
font-weight: bold;
color: rgb(0, 0, 0);
font-size: 19px;
transition: 0.5s;
}

.pie2{
  display: flex;
align-items: center;
justify-content: center;
height:100%;
width: 100%;
cursor: pointer;
font-weight: bold;
color: black;
font-size: 19px;

text-decoration: none;
transition: 0.5s;
}

.pie:hover{
  background-color: #0d5c63;
  color: black;

}
.pie2:hover{

  color: rgb(255, 255, 255);

}

.col1{
  display: flex;
  flex-direction: column;
  
  width: 25%;
  justify-content: space-between;
  height: 100%;
 
}
.col2{
   margin-top: 5px;
  width: 25%;
  justify-content: center;
  height: 100%;

  
  
}
.titulo{
  height: 100%;
  width: 100%;
}

.b{
  display: flex;
  height: 32px;
  vertical-align: middle;
  margin-bottom: 6px;
}
.cc{
  margin-top: 6px;
  height: 26px;
}

.info1{
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 10%;

  
   }
   .info111{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 10%;
  
    
     }
  

   .info1e{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 14%;
  
    
     }
     .info1n{
      display: flex;
      flex-direction: row;
      width: 100%;
      height: 9%;
    
      
       }
  

   .inpus2{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 50%;
    font-size: 14px;
   
     margin-top: 6%;
   
   }
   .opti{
   margin-bottom: 10px;
   }

   .inpus23{
    display: flex;
    flex-direction: row;
    width: 50%;
   
    justify-content: center;
    vertical-align: middle;
   
   
   }

  .texto{
    display: flex;

width: 50%;
height: 80%;
justify-content: right;
margin-top: 3%;

  }
 
  .label{
    width: 100%;
     text-align: right;
     font-size: 18px;
     justify-content: right;
     font-weight: lighter;
     
  }

  .content{
   display:flex;
   flex-direction: column;
width: 50%;
height: 100%;
vertical-align: middle;
  }
  .contentar{
    display:flex;
   
    width: 50%;
   
    height: 150px;
 
   }


  .in{
    display: flex;
    margin-top: 8%;
    height: 55%;
    width: 100%;
  }


 
  


  .info3{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 35%;
    align-items:flex-end;

  }

  .info3D{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 35%;
    justify-content: right;
    align-items:flex-end;

  }

  .info3B{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 35%;
  
  }
  .info3BDet{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 30%;
    margin-bottom: 8px;
  }
  .info3BDet2{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 40%;
    margin-bottom: 8px;
  }


  .info3B{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 30%;
  
  }
  .info3B2{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 40%;
  
  }
  .info3B1{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 15%;

  }
  
  .info141{
 
    display: flex;
    flex-direction: column;
    width: 100%;
    height:18%;
    align-items: flex-end;
  
    
    }
    .texto2{
      display: flex;
       width:100%;
       height: 20%;


  margin-bottom: 5PX;
    }

    .label23{
      font-family: Verdana, Geneva, Tahoma, sans-serif;
      font-size: 15PX;
      font-weight: 400;
      width: 100%;

      justify-content:space-around;

     
 
    
    }
  
    .coorde{
      
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;
    background-color: #44a1a0;
    width: 90%;
    height: 35%;
    margin-bottom: 8px;
    border-radius: 5px;
    margin-top: 5px;
    border-style: solid;
    border-color: rgb(51, 51, 51);
    border-width: 1px;
    
    }


  .info1coment{
    display: flex;
    flex-direction: row;
    width: 100%;
    height:20%;
 

  }

  .info1comentD{
    display: flex;
    flex-direction: row;
    width: 100%;
    height:20%;
  }
  
  .info14{
  
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 8%;
    margin-bottom: 8px;
    justify-content: center;
    background-color: #ff0000;
    
    }
  


.labelH{
  width: 45%;
   text-align: right;
   text-decoration: none;
   color: rgb(0, 0, 0);
   text-decoration: underline;
   font-style: italic;
   font-weight: bold;
   
}
.labelH:hover{
  color:#135e47;
}
.labelo{
  width: 40%;
  margin-bottom: 10px;;
  text-align: right;
}
  
.label2{
 font-weight: bold;
}

.carru{
  max-width: 160px;
  max-width: 160px;
}
.carrusel{
  margin-top: 5px;
}
.cargar{

  margin-top: 15px;
}
.cargar2{
  font-size: 16px;
  margin: 15px;
}
.cargar23{
  margin: 15px;
  color: #f59999;
font-style: italic;
  
}
.cargar2:hover{
  color: white;
}

.coor{

  width: 40px;

  margin-bottom:10px;
  font-size: 14px;
  font-weight: bold;
  margin-top: 7px;

  height: 25px;
}
.coorden{
  width: 45px;
  margin-bottom:10px;
  font-size: 14px;
  height: 25px;
  margin-top: 7px;
  margin-left: 5px;
  font-weight: bold;
}
.coorseg{
  height: 25px;
  width:70px;
  margin-bottom:10px;
  font-size: 14px;
  font-weight: bold;
  margin-top: 7px;
}
.coorde2{
 
display: flex;

flex-direction: column;
background-color: #70b19d;
width: 80%;
margin: 0px;
border-radius: 5px;
}
.p{
  font-weight: bold;
  font-size: 20px;
  margin: 5px;
}


.datos34{
  display: flex;
   width:100%;
    height: 40px;
 
    
}



.coordeIn{
  display: flex;

  max-width: 95%;
}
.textar{
 width: 100%;
  background-color: #ffffff;
  height: 80%;

}

.inpu{
 display: flex;
 flex-direction: row;
 justify-content: space-around;
  width: 187px;
  vertical-align: middle;
  justify-content: space-around;
}
.inpus{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
   width: 187px;
   vertical-align: middle;
 
   margin-top: 10px;
 }

.mostrador{
  background-color: #a0c1c0bd;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  vertical-align: middle;
  width: 85%;
  min-height: 80%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-style: solid;
  border-color: rgb(51, 51, 51);
  border-width: 1px;
  margin-top: 5px;

}
/* .mostradores{
height: 100px;
} */
.mostradorDet{
  background-color: #a0c1c0bd;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  vertical-align: middle;
  width: 85%;
  min-height: 80%;
 border-radius: 5px;
  border-style: solid;
  border-color: rgb(51, 51, 51);
  border-width: 1px;

}
.mostrador3{
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
margin-right: 30px;
  justify-content: center;
  vertical-align: middle;
  min-height:85%;
  border-radius: 5px;
  width: 85%;

  background-color:#a0c1c0bd;
  border-style: solid;
  border-color: rgb(51, 51, 51);
  border-width: 1px;

 
}

.base1{
  font-size: 12px;
  background-color: #0d5c63;
  height: 25px;
  width: 85%;
  color: rgb(255, 255, 255);
  cursor: pointer;
  transition: 0.5s;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-style: solid;
  border-color: #333;
  border-width: 1px;
  margin-bottom: 1px;

}
.mostrador2{


  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  vertical-align: middle;
  min-height: 80%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-style: solid;
  border-color: #333;
  border-width: 1px;
  width: 85%;
  background-color:#a0c1c0bd;

}
.mostradorD{
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
 

  min-height:140px;
  border-radius: 5px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  width: 85%;
  background-color:#a0c1c0bd;
 
}
.mostrador4{
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  margin-left: 28px;
  justify-content: center;
  vertical-align: middle;
  min-height:155px;
  border-radius: 5px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  width: 85%;
  background-color:#a0c1c0bd;
 
}
.ubic{
  margin-top: 5px;
  width:50px;
  margin-right: 7px;
  height: 25px;
}
.estante{
  margin-left: 28px;
  display: flex;
  flex-direction: row;
  height: 35px;
  width: 85%;
  background-color:   #a0c1c0bd;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-style: solid;
  border-color: rgb(51, 51, 51);
  border-width: 1px;

}
.estanteD{
  margin-left: 28px;
  height: 35px;
  width: 85%;
  background-color:   #a0c1c0bd;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  align-items: center;
  align-content: center;
}
.cabeza{
  background-color: #0d5c63;
  width: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  height: 38px;
  border:#333;
  border-style: solid;
  border-width: 1px;
 
}
.cabeza5{
  display: flex;
  margin-left: 28px;
  background-color: #0d5c63;
  width: 85%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  height: 41px;
  margin-top: 8px;
  justify-content: center;
  border-style: solid;
  border-color: rgb(51, 51, 51);
  border-width: 1px;

}
.cabeza3{
  background-color: #0d5c63;
  width: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  height: 78px;

}
.img-div{
  margin-top: 6%;
  height: 80%;
}




.mostrador3D{
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
  justify-content: center;
  vertical-align: middle;
  min-height:90%;
  border-radius: 5px;
  width: 85%;
  background-color:#a0c1c0bd;

 
}

.mostrador3B{
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
  margin-left: 28px;
  justify-content: center;
  vertical-align: middle;
  min-height:90%;
  border-radius: 5px;
  width: 85%;
  background-color:#a0c1c0bd;
 
}
.mostrador3B2{
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
  margin-left: 28px;
  justify-content: center;
  vertical-align: middle;
  min-height:90%;
  border-radius: 5px;
  width: 85%;
  background-color:#a0c1c0bd;
 
}
.buto{
  width: 80%;
  
}
.butono{
  font-style: italic;
  font-size: 10px;
  margin: 1px;
}
.lab{
  color: #ffffff;
 
}
.boton-copiar{
  background-color: transparent;
  border: none;
}
.boton-copiar-1{
  border: solid 1px ;
  border-left: none;
}
.sin{
  margin-top: 27px;
  color: black;
}
.fff{
  background-color: #135e47
  

}
.caca{
  border-radius: 5px;
  margin:3px;
  background-color: #0d5c63;
  max-width:100%;
  height: 25px;
  font-size: 12px;
  color: rgb(255, 255, 255);
 
  cursor: pointer;
 transition: 0.5s;
  vertical-align: middle;
}
.caca99{
  border-radius: 5px;
  margin:5px;
  background-color: #515547;
  width: 150px;
  height: 25px;
  font-size: 14px;
  color: rgb(255, 255, 255);
 
  cursor: pointer;
 transition: 0.5s;
  vertical-align: middle;
}

.caca32{
  text-decoration: none;
  border-radius: 5px;
  margin: 3px;
  background-color: #0d5c63;
  width: 45%;
  height: 23px;
  font-size: 14px;
  color: rgb(255, 255, 255);
  font-style: italic;
  font-weight: bold;
  cursor: pointer;
  
 
  vertical-align: middle;
}
.caca32B{
  text-decoration: none;
  border-radius: 5px;
  margin: 3px;
  background-color: #0d5c63;
  cursor: pointer;
  height: 23px;
  font-size: 12px;
  color: rgb(255, 251, 251);
  font-style: italic;
  font-weight: bold;
  
 
  vertical-align: middle;
}
a{
  text-decoration: none;
  
}

.caca326{
  text-decoration: none;
  border-radius: 5px;
  margin: 6px;
  background-color: #6b705c;
  width:80%;
  height: 25px;
  font-size: 12px;
  color: black;
  font-style: italic;
  font-weight: bold;
  vertical-align: middle;
}

.caca:hover{
  background-color: #44a1a0;
  color: rgb(0, 0, 0);  
}
.caca2{
  border-radius: 5px;
  margin: 3px;
  background-color: #6b705c;
  width:260px;
  height: 25px;
  font-size: 14px;
  color: black;
  font-style: italic;
  font-weight: bold;
  cursor: pointer;
 
  vertical-align: middle;
}


.base1D{
  text-decoration: none;
  background-color: #0d5c63;
  height: 25px;
  margin-left: 10px;
  width: 76%;
  color: rgb(255, 255, 255);
  cursor: pointer;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  transition: 0.5s;
  border:#333;
    border-style: solid;
    border-width: 1px;
}


.base2{
  
  background-color: #0d5c63;
  height: 25px;
  width: 85%;
  color: rgb(255, 255, 255);
  margin-left: 28px;
  margin-bottom: 5px;
  cursor: pointer;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  transition: 0.5s;
}
.h67{
  margin-top: 3px;
 font-size: 13px;
  font-style: italic;
}
.base1:hover{
  background-color:#44a1a0;
 
  color: rgb(0, 0, 0);
  font-weight: bold;
}
.base1D:hover{
 
  background-color: #44a1a0;
  color: rgb(0, 0, 0);
  font-weight: bold;
}
.pop{
  text-decoration: none;
}


.colu1{
  margin-left: 2%;
}
.info1C{
  width: 100%;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.p2{
 
  font-size: 12px;
  color: rgb(0, 0, 0);
  cursor: pointer;
  background-color:#e26262;
border-bottom-left-radius: 5px;
border-bottom-right-radius: 5px;
 transition: 0.5s;
  height: 33%;
  width: 100%;
  border:#333;
  border-style: solid;
  border-width: 1px;

}
.p2:hover{
  background-color:#62e2a2;
}

.pdfs{
  width: 10%;
}
.resultado{
  font-size: 10;
  color: #135e47;
}
.col3{
  width: 25%;
  background-color: aquamarine;

}
.uli{
  font-size: 14px;
  font-weight: lighter;
}


[tooltip]{
  position:relative;
}

/* Applies to all tooltips */
[tooltip]::before,
[tooltip]::after {
  text-transform: none; /* opinion 2 */
  font-size: .9em; /* opinion 3 */
  line-height: 1;
  user-select: none;
  pointer-events: none;
  position: absolute;
  display: none;
  opacity: 0;
}
[tooltip]::before {
  content: '';
  border: 5px solid transparent; /* opinion 4 */
  z-index: 1001; /* absurdity 1 */
}
[tooltip]::after {
  content: attr(tooltip); /* magic! */
  
  /* most of the rest of this is opinion */
  font-family: Helvetica, sans-serif;
  text-align: center;
  
  /* 
    Let the content set the size of the tooltips 
    but this will also keep them from being obnoxious
    */
  min-width: 3em;
  max-width: 21em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 1ch 1.5ch;
  border-radius: .3ch;
  box-shadow: 0 1em 2em -.5em rgba(0, 0, 0, 0.35);
  background: #333;
  color: #fff;
  z-index: 1000; /* absurdity 2 */
}

/* Make the tooltips respond to hover */
[tooltip]:hover::before,
[tooltip]:hover::after {
  display: block;
}

/* don't show empty tooltips */
[tooltip='']::before,
[tooltip='']::after {
  display: none !important;
}

/* FLOW: UP */
[tooltip]:not([flow])::before,
[tooltip][flow^="up"]::before {
  bottom: 100%;
  border-bottom-width: 0;
  border-top-color: #333;
}
[tooltip]:not([flow])::after,
[tooltip][flow^="up"]::after {
  bottom: calc(100% + 5px);
}
[tooltip]:not([flow])::before,
[tooltip]:not([flow])::after,
[tooltip][flow^="up"]::before,
[tooltip][flow^="up"]::after {
  left: 50%;
  transform: translate(-50%, -.5em);
}

/* FLOW: DOWN */
[tooltip][flow^="down"]::before {
  top: 100%;
  border-top-width: 0;
  border-bottom-color: #333;
}
[tooltip][flow^="down"]::after {
  top: calc(100% + 5px);
}
[tooltip][flow^="down"]::before,
[tooltip][flow^="down"]::after {
  left: 50%;
  transform: translate(-50%, .5em);
}

/* FLOW: LEFT */
[tooltip][flow^="left"]::before {
  top: 50%;
  border-right-width: 0;
  border-left-color: #333;
  left: calc(0em - 5px);
  transform: translate(-.5em, -50%);
}
[tooltip][flow^="left"]::after {
  top: 50%;
  right: calc(100% + 5px);
  transform: translate(-.5em, -50%);
}

/* FLOW: RIGHT */
[tooltip][flow^="right"]::before {
  top: 50%;
  border-left-width: 0;
  border-right-color: #333;
  right: calc(0em - 5px);
  transform: translate(.5em, -50%);
}
[tooltip][flow^="right"]::after {
  top: 50%;
  left: calc(100% + 5px);
  transform: translate(.5em, -50%);
}

/* KEYFRAMES */
@keyframes tooltips-vert {
  to {
    opacity: .9;
    transform: translate(-50%, 0);
  }
}

@keyframes tooltips-horz {
  to {
    opacity: .9;
    transform: translate(0, -50%);
  }
}

/* FX All The Things */ 
[tooltip]:not([flow]):hover::before,
[tooltip]:not([flow]):hover::after,
[tooltip][flow^="up"]:hover::before,
[tooltip][flow^="up"]:hover::after,
[tooltip][flow^="down"]:hover::before,
[tooltip][flow^="down"]:hover::after {
  animation: tooltips-vert 300ms ease-out forwards;
}

[tooltip][flow^="left"]:hover::before,
[tooltip][flow^="left"]:hover::after,
[tooltip][flow^="right"]:hover::before,
[tooltip][flow^="right"]:hover::after {
  animation: tooltips-horz 300ms ease-out forwards;
}










/* UNRELATED to tooltips */
body {
  margin: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: sans-serif;
  background: #ededed;
}
main {
  flex: 1 1 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
aside {
  flex: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #49b293;
  color: #fff;
  padding: 1em;
}
main div {
  text-align: center;
  color: #353539;
}
main span {
  padding: .5em 1em;
  margin: .5em;
  display: inline-block;
  background: #dedede;
}

aside a {
  color: inherit;
  text-decoration: none;
  font-weight: bold;
  display: inline-block;
  padding: .4em 1em;
}

.datosCur{
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-weight: bold;
 
  margin-top: 12px;
}
.datosCur2{
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-weight: bold;
  font-style: oblique;
  margin-top: 12px;
}
.datosCur3{
  font-style: oblique;
 
}

.col1D{
  justify-content:left ;
}



