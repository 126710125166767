
.padre{
  width: 100%;
  position: fixed;
}

.nav-Bar1{
  width: 100%;
  background-color:#0d5c63;
  /* height: 36%; */

  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  height: 80px;
  justify-content: space-between;
}
 .img-perfil{
  border-radius: 200px;
}
.botones{
  display: flex;
  background-color:#0d5c63;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.botonCito{
  justify-content: center;
  cursor: pointer;
  display: flex;
  width:15%;
  height: 80%;
  margin-top: 30px;
 border-top-left-radius: 10px;
 border-top-right-radius: 10px;
  color: white;
  transition: 0.3s;
  font-size: 17px;  
}
 .active2,.botonCito:hover{

  background-color:#44a1a0;
  height: 80%;
  margin-top: 30px;

  color: black;
  
  
}
.imag{
  cursor: pointer;

}
.pboton{
  margin-top: 5px;
}

.logo-home{
  margin-top: 5px;
  margin-left: 7px;
 

}

.perfil{
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-evenly;
  padding-right: 6px;
}
.boton-catalogo{
  padding: 1%;
 
}
.boton-icono{
  color :white;
}
.boton-icono:hover{
  color: black;
  cursor: pointer;
}
